import React, { Fragment, FC } from 'react';
// import { Header } from '../Header';
// import { Footer } from '../Footer';

const Layout: FC = ({ children }) => {
  return (
    <Fragment>
      {/* <Header /> */}
      <div>{children}</div>
      {/* <Footer /> */}
    </Fragment>
  );
};
export default Layout;
