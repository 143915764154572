import colors from './colors';

const colorAliases = {
  // Text
  textBody: colors.black,
  textHeading: colors.black,
  // textMuted: colors.grey400,

  // Link
  linkText: colors.black,
  linkHover: colors.grey,

  // Borders
  border: colors.grey,
};

export default colorAliases;
