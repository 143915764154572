import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home } from '../components/Home';

const RouterSwitch: FC = () => (
  <Switch>
    {/* Home */}
    <Route path="/" exact component={Home} />
  </Switch>
);

export { RouterSwitch };
