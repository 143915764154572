import styled from 'styled-components';

export const Contact = styled.div`
  width: 100%;
  text-align: center;

  a {
    background: #f7d048;
    padding: 5px 10px;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.Noto};
  font-size: 7vw;
  width: 100%;
  text-align: center;
`;

export const Wraper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  flex-wrap: wrap;
`;
