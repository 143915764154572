import { DefaultTheme } from 'styled-components';
import colors from './colors';
import colorAliases from './colorAliases';
import { fonts, fontWeights } from './typography';

export const screenSizes = {
  sm: 576,
  md: 758,
  lg: 992,
  xl: 1200,
} as { [key: string]: number };

const mediaQueries = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = `@media screen and (min-width: ${screenSizes[label]}px)`;
  return acc;
}, {} as { [key: string]: string });

const theme: DefaultTheme = {
  colors: {
    ...colors,
    ...colorAliases,
  },
  fonts,
  durations: {
    fast: '150ms',
    normal: '300ms',
  },
  fontWeights,
  mediaQueries,
};

export default theme;
