import { DefaultTheme, StyledProps } from 'styled-components';
import type { Palette } from '../Theme/colors';

export const flattenColors: any = (palette: Palette) => {
  const colors: Record<string, string> = {};

  Object.entries(palette).forEach(([color, shades]) => {
    Object.entries(shades).forEach(([shade, value]) => {
      colors[`${color}${shade}`] = value;
    });
  });

  return colors;
};

export const responsive = (size: string) => (props: StyledProps<unknown>): string => {
  const { mediaQueries }: DefaultTheme = props.theme;
  return mediaQueries[size];
};
