import React, { FC } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet: FC = () => {
  return (
    <ReactHelmet>
      <title>Fawz food and spices</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Roboto:ital,wght@0,300;0,400;1,400&display=swap"
        rel="stylesheet"
      />
    </ReactHelmet>
  );
};

export default React.memo(Helmet);
