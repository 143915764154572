import { flattenColors } from '../utils/theme-utils';

export type Shade = Record<number, string>;
export type Palette = Record<string, Shade>;

const palette: Palette = {
  grey: {
    50: '#E8E8E8',
    100: '#D1D1D1',
    200: '#B9B9B9',
    300: '#A2A2A2',
    400: '#8B8B8B',
    500: '#747474',
    600: '#535353',
    700: '#464646',
    800: '#333',
    900: '#171717',
  },
};

const baseColors = {
  black: '#000',
  white: '#fff',
  grey: '#B9B9B9',
  red: '#E5241F',
};

const colors = {
  transparent: 'transparent',
  ...baseColors,
  ...flattenColors(palette),
};

export default colors;
