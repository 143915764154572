const baseFont = 'Arial, Helvetica, sans-serif';

const fonts = {
  Noto: `Noto Serif, ${baseFont}`,
  assistant: `Assistant, ${baseFont}`,
  roboto: `Roboto, ${baseFont}`,
  arial: baseFont,
};

const fontWeights = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
};

export { fonts, fontWeights };
