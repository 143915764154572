import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from './components/Helmet';
import { Theme, GlobalStyle } from './Theme';
import { RouterSwitch } from './routes';
import { Layout } from './components/Layout';
import './assets/styles/index.scss';

const App: FC = () => {
  return (
    <Theme>
      <Helmet />
      <GlobalStyle />
      <BrowserRouter>
        <Layout>
          <RouterSwitch />
        </Layout>
      </BrowserRouter>
    </Theme>
  );
};

export default App;
