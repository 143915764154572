import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 100%;
  }
  body {
    color: ${props => props.theme.colors.black};
    background-color:${props => props.theme.colors.white};
    font: 14px/16px ${props => props.theme.fonts.roboto};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 360px;
  }
  
  /**
  * Reset links to optimize for opt-in styling instead of
  * opt-out.
  */

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default GlobalStyle;
