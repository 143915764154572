import React, { FC } from 'react';
import { Wraper, Title, Contact } from './Home.styles';

const Home: FC = () => (
  <Wraper>
    <div>
      <Title>Fawz Foods & Spices</Title>
      <Contact>
        email to <a href="mailto:hello@fawzfoods.com">hello@fawzfoods.com</a>
      </Contact>
    </div>
  </Wraper>
);

export default Home;
